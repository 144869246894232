import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import './TBTCUserAuthenticator.css';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInput.css';

import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useWeb3React } from '@web3-react/core';
import Web3ConnectModal from './Web3ConnectModal';
import AlertModal from './Modals/AlertModal';
import { isMobile } from 'react-device-detect';

declare let window: any;

interface TBTCUserAuthenticatorProps {
  groups: string;
  onSigninSuccess: any;
  signup: boolean;
  userManagementApiBase: string;
  styles: any;
  googleRecaptchaSiteKey: any;
}

const WalletConnect = new WalletConnectConnector({
  // @ts-ignore
  rpcUrl: 'https://rinkeby.infura.io/v3/9d097d80ee584f869bf6588285180249',
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

export function TBTCUserSigninSignup({
  groups,
  onSigninSuccess,
  signup,
  userManagementApiBase,
  styles,
  googleRecaptchaSiteKey,
}: TBTCUserAuthenticatorProps) {
  // const googleRecaptchaSiteKey = '6LdpnzAkAAAAAJjK1F2RC07LSl-IAmfQZWgmoJJP';
  const [errMsg, setErrMsg] = useState('');
  // const [recaptchValue, setRecaptchValue] = useState<any>(null);
  const [signinObj, setSigninObj] = useState<any>({});
  const [signupObj, setSignupObj] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationObj, setConfirmationObj] = useState<any>({});
  const [softwareTokenMFAObj, setSoftwareTokenMFAObj] = useState<any>({});
  const [forgotPassewordObj, setForgotPasswordObj] = useState<any>({});
  const [confirmForgotPasswordObj, setConfirmForgotPasswordObj] = useState<any>(
    {}
  );
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const reCaptchaRef = useRef<any>();

  const allStyles = styles || {};
  const [
    signinFieldValidationStatus,
    setSigninFieldValidationStatus,
  ] = useState({
    email: true,
    password: true,
  });
  const [
    forgotPasswordFieldValidationStatus,
    setForgotPasswordFieldValidationStatus,
  ] = useState({
    email: true,
  });
  const [
    forgotPasswordConfirmationFieldValidationStatus,
    setForgotPasswordConfirmationFieldValidationStatus,
  ] = useState({
    email: true,
    password: true,
    confirmPassword: true,
    code: true,
  });
  const [
    confirmationFieldValidationStatus,
    setConfirmationFieldValidationStatus,
  ] = useState({
    email: true,
    code: true,
  });
  const [
    softwareTokenMFAFieldValidationStatus,
    setSoftwareTokenMFAFieldValidationStatus,
  ] = useState({
    email: true,
    code: true,
  });
  const [
    signupFieldValidationStatus,
    setSignupFieldValidationStatus,
  ] = useState({
    email: true,
    name: true,
    familyName: true,
    phoneNumber: true,
    password: true,
    confirmPassword: true,
  });
  const [domVisibility, setDomVisibility] = useState<any>({
    signin: true,
    signup: false,
    confirmation: false,
    forgotPasseord: false,
    confirmForgotPassword: false,
    softwareTokenMFA: false,
  });
  const [isSignin, setIsSignin] = useState(true);
  //   const { chainId, account, activate, active, library, deactivate } =
  //     useWeb3React();

  const { account, activate, library } = useWeb3React();

  const [showWalletConnectionModal, setShowWalletConnectionModal] = useState(
    false
  );

  const handleWalletConnectionModalClose = () =>
    setShowWalletConnectionModal(false);
  const handleWalletConnectionModalShow = (event: any, isSignin: boolean) => {
    event.preventDefault();
    setIsSignin(isSignin);
    setShowWalletConnectionModal(true);
  };

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleAlertModalClose = () => setShowAlertModal(false);

  const showAlert = (message: any) => {
    setAlertMessage(message);
    setShowAlertModal(true);
  };

  useEffect(() => {
    // const provider = window.localStorage.getItem("provider");
    // if (provider) activate(WalletConnect);
  }, []);

  useEffect(() => {
    reCaptchaRef?.current && reCaptchaRef.current.reset();
  }, []);

  const BASE_URL = `${userManagementApiBase}/api`;

  const handleSigninObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setSigninObj((values: any) => ({ ...values, [name]: value }));
  };

  const handleSignupObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setSignupObj((values: any) => ({ ...values, [name]: value }));
  };
  const handleConfirmationObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setConfirmationObj((values: any) => ({ ...values, [name]: value }));
  };
  const handleSoftwareTokenMFAObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setSoftwareTokenMFAObj((values: any) => ({ ...values, [name]: value }));
  };
  const handleForgotPasswordObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setForgotPasswordObj((values: any) => ({ ...values, [name]: value }));
  };
  const handleConfirmForgotPasswordObjChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setConfirmForgotPasswordObj((values: any) => ({
      ...values,
      [name]: value,
    }));
  };
  const isValidEmail = (email: any) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

  const handleSigninSubmit = async (event: any) => {
    event.preventDefault();
    const validEmail = !!signinObj?.email && isValidEmail(signinObj?.email);
    const validationObj = {
      ...signinFieldValidationStatus,
      email: validEmail,
      password: !!signinObj?.password,
    };
    setSigninFieldValidationStatus({ ...validationObj });
    if (validEmail && !!signinObj?.password) {
      const token = await reCaptchaRef.current.executeAsync();
      if (!!token) {
        authenticateUser('GetAuthToken', {
          ...signinObj,
          captchaToken: token,
        });
      }
    }
  };

  const handelSoftwareTokenMFASubmit = () => {
    const validEmail =
      !!softwareTokenMFAObj?.email && isValidEmail(softwareTokenMFAObj?.email);
    const validationObj = {
      ...softwareTokenMFAFieldValidationStatus,
      email: validEmail,
      code: !!softwareTokenMFAObj?.code,
    };
    setSoftwareTokenMFAFieldValidationStatus({ ...validationObj });
    if (!!softwareTokenMFAObj?.code) {
      setIsLoading(true);
      fetch(`${BASE_URL}/RespondToAuthChallengeAndGetToken`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: softwareTokenMFAObj?.email,
          mFACode: softwareTokenMFAObj?.code,
          session: softwareTokenMFAObj?.config?.session,
        }),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          return res.json();
        })
        .then(response => {
          onSigninSuccess(response);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };

  const handelConfirmationSubmit = () => {
    const validEmail =
      !!confirmationObj?.email && isValidEmail(confirmationObj?.email);
    const validationObj = {
      ...confirmationFieldValidationStatus,
      email: validEmail,
      code: !!confirmationObj?.code,
    };
    setConfirmationFieldValidationStatus({ ...validationObj });
    if (!!confirmationObj?.code) {
      setIsLoading(true);
      fetch(`${BASE_URL}/ConfirmUser`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(confirmationObj),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          showAlert('Successfully confirmed!');
          setIsLoading(false);
          visibleSigninForm();
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };

  const handelForgotPasswordSubmit = () => {
    const validEmail =
      !!forgotPassewordObj?.email && isValidEmail(forgotPassewordObj?.email);
    const validationObj = {
      ...forgotPasswordFieldValidationStatus,
      email: validEmail,
    };
    setForgotPasswordFieldValidationStatus({ ...validationObj });
    if (validEmail) {
      setIsLoading(true);
      fetch(`${BASE_URL}/ForgotPassword`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: forgotPassewordObj?.email }),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          showAlert(
            'Please check email for your verification code enter this along with your new password.'
          );
          setIsLoading(false);
          visibleConfirmForgotPasswordForm(forgotPassewordObj?.email);
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };
  const resendForgotPasswordConfirmationCode = (event: any) => {
    event.preventDefault();
    const validEmail =
      !!forgotPassewordObj?.email && isValidEmail(forgotPassewordObj?.email);
    if (validEmail) {
      setIsLoading(true);
      fetch(`${BASE_URL}/ForgotPassword`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: forgotPassewordObj?.email }),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          showAlert(
            'Please check your email and enter the verification code you have received.'
          );
          setIsLoading(false);
          setErrMsg('');
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };
  const handelConfirmForgotPasswordSubmit = () => {
    const validEmail =
      !!confirmForgotPasswordObj?.email &&
      isValidEmail(confirmForgotPasswordObj?.email);
    const validationObj = {
      ...forgotPasswordConfirmationFieldValidationStatus,
      email: validEmail,
      password: !!confirmForgotPasswordObj?.password,
      code: !!confirmForgotPasswordObj?.code,
      confirmPassword: !!confirmForgotPasswordObj?.confirmPassword,
    };
    if (
      (confirmForgotPasswordObj?.password || '') !==
      (confirmForgotPasswordObj?.confirmPassword || '')
    ) {
      validationObj.password = false;
      validationObj.confirmPassword = false;
    }
    setForgotPasswordConfirmationFieldValidationStatus({ ...validationObj });
    if (
      validEmail &&
      !!confirmForgotPasswordObj?.code &&
      !!confirmForgotPasswordObj?.password &&
      !!confirmForgotPasswordObj?.confirmPassword &&
      confirmForgotPasswordObj?.password ===
        confirmForgotPasswordObj?.confirmPassword
    ) {
      setIsLoading(true);
      fetch(`${BASE_URL}/ConfirmForgotPassword`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(confirmForgotPasswordObj),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          showAlert('Successfully updated!');
          setIsLoading(false);
          visibleSigninForm();
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };

  const handleSignupSubmit = (event: any) => {
    event.preventDefault();
    if (!groups) {
      setErrMsg('Need to set at least one valid group!');
      return;
    }
    const validEmail = !!signupObj?.email && isValidEmail(signupObj?.email);
    const validationObj = {
      ...signupFieldValidationStatus,
      email: validEmail,
      name: !!signupObj?.name,
      familyName: !!signupObj?.familyName,
      password: !!signupObj?.password,
      confirmPassword: !!signupObj?.confirmPassword,
    };
    if ((signupObj?.password || '') !== (signupObj?.confirmPassword || '')) {
      validationObj.password = false;
      validationObj.confirmPassword = false;
    }
    setSignupFieldValidationStatus({ ...validationObj });
    if (
      validEmail &&
      !!signupObj?.name &&
      !!signupObj?.familyName &&
      !!signupObj?.password &&
      !!signupObj?.confirmPassword &&
      signupObj?.password === signupObj?.confirmPassword
    ) {
      setIsLoading(true);
      fetch(`${BASE_URL}/SignUpWeb2User`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...signupObj,
          groups: groups.split(','),
          phoneNumber: phoneNumber,
        }),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          setIsLoading(false);
          showAlert(
            'Please check your email and enter the verification code you have received.'
          );
          visibleConfirmationForm(signupObj?.email);
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };
  const connectAndGetWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      let accounts = [];
      if (isMobile) {
        accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
      } else {
        accounts = await window.ethereum
          .request({
            method: 'wallet_requestPermissions',
            params: [
              {
                eth_accounts: {},
              },
            ],
          })
          .then(() =>
            window.ethereum.request({
              method: 'eth_requestAccounts',
            })
          );
      }

      const address = accounts[0];

      if (!address) {
        return null;
      }

      const message = `This request will not trigger a blockchain transaction or cost any gas fees.\nWallet address:\n${address}`;

      const signatureHash = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, address],
      });

      return {
        walletAddress: address,
        signature: signatureHash,
      };
    }
    return null;
  };
  //   const disconnect = () => {
  //     window.localStorage.setItem("provider", undefined);
  //     deactivate();
  //   };

  const signinOrSignupWithWalletConnect = async (event: any) => {
    event?.preventDefault();
    handleWalletConnectionModalClose();
    // disconnect();
    // setIsLoading(true);
    await activate(WalletConnect);
  };
  useEffect(() => {
    if (!!account) {
      signMessage();
    }
  }, [account]);
  const signinOrSignupWithMetamask = async (event: any) => {
    event?.preventDefault();
    if (isSignin) {
      loginWithMetamask();
    } else {
      signupWithMetamask();
    }
  };
  const loginWithMetamask = async () => {
    handleWalletConnectionModalClose();
    const wallet = await connectAndGetWallet();
    if (!!wallet) {
      authenticateUser('GetWeb3AuthToken', wallet);
    }
  };
  const signMessage = async () => {
    const message = `This request will not trigger a blockchain transaction or cost any gas fees.\nWallet address:\n${account}`;
    try {
      setIsLoading(true);
      // @ts-ignore
      const signature = await library.provider.request({
        method: 'personal_sign',
        params: [message, account],
      });
      const sign = signature?.slice(-162);
      if (!!sign) {
        var model = {
          walletAddress: account,
          signature: sign,
        };
        if (isSignin) {
          await authenticateUser('GetWeb3AuthToken', model);
        } else {
          await signupWeb3(model);
        }
        //disconnect();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const signupWithMetamask = async () => {
    if (!groups) {
      setErrMsg('Need to set at least one valid group!');
      return;
    }
    const wallet = await connectAndGetWallet();
    await signupWeb3(wallet);
  };
  const signupWeb3 = async (wallet: any) => {
    if (!!wallet) {
      setIsLoading(true);
      fetch(`${BASE_URL}/SignUpWeb3User`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...wallet, groups: groups.split(',') }),
      })
        .then(res => {
          if (!res.ok) {
            return res.text().then(text => {
              throw new Error(text);
            });
          }
          setIsLoading(false);
          showAlert('Successfully created!');
          visibleSigninForm();
          return res;
        })
        .catch(error => {
          setIsLoading(false);
          const err = error?.message;
          if (!!err && typeof err === 'string') {
            setErrMsg(err);
          } else if (!err) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg('Failed!');
          }
        });
    }
  };
  const authenticateUser = async (endPoint: any, data: any) => {
    setIsLoading(true);
    fetch(`${BASE_URL}/${endPoint}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (!res.ok) {
          return res.text().then(text => {
            throw new Error(text);
          });
        }
        return res.json();
      })
      .then(response => {
        if (
          !!response.challengeName &&
          response.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
          visibleSoftwareTokenMFAForm(data.email, response);
        } else {
          onSigninSuccess(response);
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        const err = error?.message;
        if (err === 'UNCONFIRMED') {
          visibleConfirmationForm(signinObj?.email);
        } else if (!!err && typeof err === 'string') {
          setErrMsg(err);
        } else if (!err) {
          setErrMsg('No Server Response');
        } else if (err?.status === 400) {
          setErrMsg(err?.data || 'Login failed!');
          console.error(err?.data);
        } else if (err?.status === 401) {
          setErrMsg('Unauthorized');
        } else {
          setErrMsg('Wrong wallet address or signature!');
        }
      });
  };

  const visibleSignupForm = () => {
    setDomVisibility({
      signin: false,
      signup: true,
      confirmation: false,
      forgotPasseord: false,
      confirmForgotPassword: false,
      softwareTokenMFA: false,
    });
    setErrMsg('');
    setSignupObj({});
    setSignupFieldValidationStatus({
      email: true,
      name: true,
      familyName: true,
      phoneNumber: true,
      password: true,
      confirmPassword: true,
    });
  };
  const visibleSigninForm = () => {
    setDomVisibility({
      signin: true,
      signup: false,
      confirmation: false,
      forgotPasseord: false,
      confirmForgotPassword: false,
      softwareTokenMFA: false,
    });
    setErrMsg('');
    setSigninObj({});
    setSigninFieldValidationStatus({
      email: true,
      password: true,
    });
  };
  const visibleSoftwareTokenMFAForm = (email: any, config: any) => {
    setDomVisibility({
      signin: false,
      signup: false,
      confirmation: false,
      forgotPasseord: false,
      confirmForgotPassword: false,
      softwareTokenMFA: true,
    });
    setErrMsg('');
    setSoftwareTokenMFAObj({ email: email, config: config });
    setSoftwareTokenMFAFieldValidationStatus({ email: true, code: true });
  };
  const visibleConfirmationForm = (email: any) => {
    setDomVisibility({
      signin: false,
      signup: false,
      confirmation: true,
      forgotPasseord: false,
      confirmForgotPassword: false,
      softwareTokenMFA: false,
    });
    setErrMsg('');
    setConfirmationObj({ email: email });
    setConfirmationFieldValidationStatus({ email: true, code: true });
  };
  const visibleForgotPasswordForm = () => {
    setDomVisibility({
      signin: false,
      signup: false,
      confirmation: false,
      forgotPasseord: true,
      confirmForgotPassword: false,
      softwareTokenMFA: false,
    });
    setErrMsg('');
    setForgotPasswordObj({ email: '' });
    setForgotPasswordFieldValidationStatus({ email: true });
  };
  const visibleConfirmForgotPasswordForm = (email: any) => {
    setDomVisibility({
      signin: false,
      signup: false,
      confirmation: false,
      forgotPasseord: false,
      confirmForgotPassword: true,
      softwareTokenMFA: false,
    });
    setErrMsg('');
    setConfirmForgotPasswordObj({ email: email });
    setForgotPasswordConfirmationFieldValidationStatus({
      email: true,
      password: true,
      confirmPassword: true,
      code: true,
    });
  };

  const resendConfirmationCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!confirmationObj?.email) {
      return;
    }
    setIsLoading(true);
    fetch(`${BASE_URL}/ResendConfirmationCode`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: confirmationObj?.email }),
    })
      .then(res => {
        if (!res.ok) {
          return res.text().then(text => {
            throw new Error(text);
          });
        }
        showAlert(
          'Please check your email and enter the verification code you have received.'
        );
        setIsLoading(false);
        setErrMsg('');
        return res;
      })
      .catch(error => {
        setIsLoading(false);
        const err = error?.message;
        if (!!err && typeof err === 'string') {
          setErrMsg(err);
        } else if (!err) {
          setErrMsg('No Server Response');
        } else {
          setErrMsg('Failed!');
        }
      });
  };

  const showPasswordResetForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    visibleForgotPasswordForm();
  };

  return (
    <>
      <div className="tbtc-sdk-wrapper" style={allStyles?.tbtcSdkWrapper || {}}>
        {errMsg && (
          <p
            className="tbtc-sdk-text-danger"
            style={allStyles?.tbtcSdkTextDanger || {}}
            aria-live="assertive"
          >
            {errMsg}
          </p>
        )}
        {domVisibility.signin && (
          <div>
            <form onSubmit={handleSigninSubmit} noValidate>
              <div
                className="tbtc-sdk-input-wrapper"
                style={allStyles?.tbtcSdkInputWrapper || {}}
              >
                <input
                  required
                  type="email"
                  name="email"
                  value={signinObj.email || ''}
                  onChange={handleSigninObjChange}
                  className={`tbtc-sdk-input ${
                    !signinFieldValidationStatus.email
                      ? 'tbtc-sdk-invalid-input-field'
                      : ''
                  }`}
                  style={allStyles?.tbtcSdkInput || {}}
                />
                <span>EMAIL</span>
              </div>
              <div
                className="tbtc-sdk-input-wrapper"
                style={allStyles?.tbtcSdkInputWrapper || {}}
              >
                <input
                  required
                  type="password"
                  name="password"
                  value={signinObj.password || ''}
                  onChange={handleSigninObjChange}
                  className={`tbtc-sdk-input ${
                    !signinFieldValidationStatus.password
                      ? 'tbtc-sdk-invalid-input-field'
                      : ''
                  }`}
                  style={allStyles?.tbtcSdkInput || {}}
                />
                <span>PASSWORD</span>
              </div>

              <ReCAPTCHA
                sitekey={googleRecaptchaSiteKey}
                // onChange={(value) => {
                //   setRecaptchValue(value);
                // }}
                size="invisible"
                // onExpired={() => {
                //   setRecaptchValue(null);
                // }}
                ref={reCaptchaRef}
              />

              <div
                className="tbtc-sdk-forgot-password-wrapper"
                style={allStyles?.tbtcSdkForgotPasswordWrapper || {}}
              >
                <div>
                  <a
                    className="tbtc-sdk-forgot-password-link"
                    style={allStyles?.tbtcSdkForgotPasswordLink || {}}
                    href="/"
                    onClick={showPasswordResetForm}
                  >
                    Forgotten password?
                  </a>
                </div>
              </div>
              <div
                className="tbtc-sdk-button-wrapper"
                style={allStyles?.tbtcSdkButtonWrapper || {}}
              >
                <button
                  className="tbtc-sdk-btn"
                  disabled={isLoading}
                  style={allStyles?.tbtcSdkBtn || {}}
                >
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Sign In
                </button>
                <span className="tbtc-sdk-sign-in-button-seperator"> Or </span>
                {/* <div style={{ color: "white" }}>
                  {truncateAddress(account) || ""} {chainId}
                </div> */}
                <button
                  className="tbtc-sdk-btn"
                  disabled={isLoading}
                  // onClick={loginWithWallet}
                  onClick={event => {
                    handleWalletConnectionModalShow(event, true);
                  }}
                  style={allStyles?.tbtcSdkBtn || {}}
                >
                  Sign In With Wallet
                </button>{' '}
                {/* <button onClick={signMessage}>Sign</button> */}
                {signup && (
                  <button
                    disabled={isLoading}
                    className="tbtc-sdk-btn"
                    onClick={visibleSignupForm}
                    style={allStyles?.tbtcSdkBtn || {}}
                  >
                    Sign Up
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
        {domVisibility.signup && (
          <form onSubmit={handleSignupSubmit} noValidate>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="email"
                name="email"
                value={signupObj.email || ''}
                onChange={handleSignupObjChange}
                className={`tbtc-sdk-input ${
                  !signupFieldValidationStatus.email
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>EMAIL</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="text"
                name="name"
                value={signupObj.name || ''}
                onChange={handleSignupObjChange}
                className={`tbtc-sdk-input ${
                  !signupFieldValidationStatus.name
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>NAME</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="text"
                name="familyName"
                value={signupObj.familyName || ''}
                onChange={handleSignupObjChange}
                className={`tbtc-sdk-input ${
                  !signupFieldValidationStatus.familyName
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>SURNAME</span>
            </div>
            <div
              className="tbtc-sdk-phone-number-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <div
                className="phone-number"
                style={allStyles?.phoneNumber || {}}
              >
                <PhoneInput
                  country="gb"
                  placeholder=""
                  value={phoneNumber}
                  enableSearch={true}
                  onChange={(num: any) => {
                    setPhoneNumber(num && `+${num}`);
                    // console.log(num && `+${num}`);
                  }}
                />
                <label className="phone-number-label">PHONE NUMBER</label>
              </div>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="password"
                name="password"
                value={signupObj.password || ''}
                onChange={handleSignupObjChange}
                className={`tbtc-sdk-input ${
                  !signupFieldValidationStatus.password
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>PASSWORD</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="password"
                name="confirmPassword"
                value={signupObj.confirmPassword || ''}
                onChange={handleSignupObjChange}
                className={`tbtc-sdk-input ${
                  !signupFieldValidationStatus.confirmPassword
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>CONFIRM PASSWORD</span>
            </div>
            <div
              className="tbtc-sdk-button-wrapper"
              style={allStyles?.tbtcSdkButtonWrapper || {}}
            >
              <button
                className="tbtc-sdk-btn"
                disabled={isLoading}
                style={allStyles?.tbtcSdkBtn || {}}
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Sign Up
              </button>{' '}
              <button
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                disabled={isLoading}
                // onClick={signupWithWallet}
                onClick={event => {
                  handleWalletConnectionModalShow(event, false);
                }}
              >
                Sign Up With Wallet
              </button>{' '}
              <button
                disabled={isLoading}
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                onClick={visibleSigninForm}
              >
                Sign In
              </button>
            </div>
          </form>
        )}
        {domVisibility.softwareTokenMFA && (
          <>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="email"
                name="email"
                value={softwareTokenMFAObj.email || ''}
                onChange={handleSoftwareTokenMFAObjChange}
                className={`tbtc-sdk-input ${
                  !softwareTokenMFAFieldValidationStatus.email
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>EMAIL</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="text"
                name="code"
                value={softwareTokenMFAObj.code || ''}
                onChange={handleSoftwareTokenMFAObjChange}
                className={`tbtc-sdk-input ${
                  !softwareTokenMFAFieldValidationStatus.code
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>VERIFICATION CODE</span>
            </div>
            <div
              className="tbtc-sdk-code-submit-wrapper"
              style={allStyles?.tbtcSdkCodeSubmitWrapper || {}}
            >
              <button
                disabled={isLoading}
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                onClick={handelSoftwareTokenMFASubmit}
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Submit
              </button>
            </div>
          </>
        )}
        {domVisibility.confirmation && (
          <>
            {!errMsg && (
              <p
                className="tbtc-sdk-confirmation-message"
                style={allStyles?.tbtcSdkConfirmationMessage || {}}
              >
                Please check your email and enter the verification code you have
                received.
              </p>
            )}
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="email"
                name="email"
                value={confirmationObj.email || ''}
                onChange={handleConfirmationObjChange}
                className={`tbtc-sdk-input ${
                  !confirmationFieldValidationStatus.email
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>EMAIL</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="text"
                name="code"
                value={confirmationObj.code || ''}
                onChange={handleConfirmationObjChange}
                className={`tbtc-sdk-input ${
                  !confirmationFieldValidationStatus.code
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>VERIFICATION CODE</span>
            </div>
            <div
              className="tbtc-sdk-code-submit-wrapper"
              style={allStyles?.tbtcSdkCodeSubmitWrapper || {}}
            >
              <button
                disabled={isLoading}
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                onClick={handelConfirmationSubmit}
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Submit
              </button>
              <a
                className="tbtc-sdk-resend-confirmation-code-link"
                style={allStyles?.tbtcSdkResendConfirmationCodeLink || {}}
                href="/"
                onClick={resendConfirmationCode}
              >
                Resend Verification Code
              </a>
            </div>
          </>
        )}
        {domVisibility.forgotPasseord && (
          <>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="email"
                name="email"
                value={forgotPassewordObj.email || ''}
                onChange={handleForgotPasswordObjChange}
                className={`tbtc-sdk-input ${
                  !forgotPasswordFieldValidationStatus.email
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>EMAIL</span>
            </div>
            <button
              disabled={isLoading}
              className="tbtc-sdk-btn"
              style={allStyles?.tbtcSdkBtn || {}}
              onClick={handelForgotPasswordSubmit}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>{' '}
            <button
              disabled={isLoading}
              className="tbtc-sdk-btn"
              style={allStyles?.tbtcSdkBtn || {}}
              onClick={visibleSigninForm}
            >
              Sign In
            </button>
          </>
        )}
        {domVisibility.confirmForgotPassword && (
          <>
            {!errMsg && (
              <p
                className="tbtc-sdk-confirmation-message"
                style={allStyles?.tbtcSdkConfirmationMessage || {}}
              >
                Please check email for your verification code enter this along
                with your new password.
              </p>
            )}
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="email"
                name="email"
                value={confirmForgotPasswordObj.email || ''}
                onChange={handleConfirmForgotPasswordObjChange}
                className={`tbtc-sdk-input ${
                  !forgotPasswordConfirmationFieldValidationStatus.email
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>EMAIL</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="password"
                name="password"
                value={confirmForgotPasswordObj.password || ''}
                onChange={handleConfirmForgotPasswordObjChange}
                className={`tbtc-sdk-input ${
                  !forgotPasswordConfirmationFieldValidationStatus.password
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>SET PASSWORD</span>
            </div>
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="password"
                name="confirmPassword"
                value={confirmForgotPasswordObj.confirmPassword || ''}
                onChange={handleConfirmForgotPasswordObjChange}
                className={`tbtc-sdk-input ${
                  !forgotPasswordConfirmationFieldValidationStatus.confirmPassword
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>CONFIRM PASSWORD</span>
            </div>
            {/* <div className="password tbtc-sdk-input-wrapper">
              <input
                required
                type="password"
                name="confirmPassword"
                value={confirmForgotPasswordObj.confirmPassword || ""}
                onChange={handleConfirmForgotPasswordObjChange}
              />
              <span>CONFIRM PASSWORD</span>
            </div> */}
            <div
              className="tbtc-sdk-input-wrapper"
              style={allStyles?.tbtcSdkInputWrapper || {}}
            >
              <input
                required
                type="text"
                name="code"
                value={confirmForgotPasswordObj.code || ''}
                onChange={handleConfirmForgotPasswordObjChange}
                className={`tbtc-sdk-input ${
                  !forgotPasswordConfirmationFieldValidationStatus.code
                    ? 'tbtc-sdk-invalid-input-field'
                    : ''
                }`}
                style={allStyles?.tbtcSdkInput || {}}
              />
              <span>VERIFICATION CODE</span>
            </div>
            <div
              className="tbtc-sdk-forgot-password-wrapper"
              style={allStyles?.tbtcSdkForgotPasswordWrapper || {}}
            >
              <div>
                <a
                  className="tbtc-sdk-resend-forgot-password-confirmation-code-link"
                  style={
                    allStyles?.tbtcSdkResendForgotPasswordConfirmationCodeLink ||
                    {}
                  }
                  href="/"
                  onClick={resendForgotPasswordConfirmationCode}
                >
                  Resend Password Verification Code
                </a>
              </div>
            </div>
            <div className="tbtc-sdk-code-submit-wrapper">
              <button
                disabled={isLoading}
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                onClick={handelConfirmForgotPasswordSubmit}
              >
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Submit
              </button>{' '}
              {/* <button
                disabled={isLoading}
                className="tbtc-sdk-btn"
                style={allStyles?.tbtcSdkBtn || {}}
                onClick={visibleSigninForm}
              >
                Sign In
              </button> */}
            </div>
          </>
        )}
      </div>
      <AlertModal
        message={alertMessage}
        isOpen={showAlertModal}
        handleClose={handleAlertModalClose}
      />

      <Web3ConnectModal
        isOpen={showWalletConnectionModal}
        handleClose={() => handleWalletConnectionModalClose()}
        handleMetamask={signinOrSignupWithMetamask}
        handleWalletConnect={signinOrSignupWithWalletConnect}
      />
    </>
  );
}
