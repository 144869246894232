import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';
import { TBTCUserSigninSignup } from './TBTCUserSigninSignup';
import 'bootstrap/dist/css/bootstrap.min.css';
declare let window: any;

interface TBTCUserAuthenticatorProps {
  groups: string;
  onSigninSuccess: any;
  signup: boolean;
  userManagementApiBase: string;
  styles: any;
  googleRecaptchaSiteKey: any;
}

const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000; // frequency provider is polling
  return library;
};

export function TBTCUserAuthenticator({
  groups,
  onSigninSuccess,
  signup,
  userManagementApiBase,
  styles,
  googleRecaptchaSiteKey,
}: TBTCUserAuthenticatorProps) {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <TBTCUserSigninSignup
          groups={groups}
          onSigninSuccess={onSigninSuccess}
          signup={signup}
          userManagementApiBase={userManagementApiBase}
          styles={styles}
          googleRecaptchaSiteKey={googleRecaptchaSiteKey}
        />
      </Web3ReactProvider>
    </>
  );
}
