import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { COLOR_WHITE } from '../colors';
import { Body1 } from '../Typography';

const OkButton = styled.button`
  background: #e6b812;
  border: none;
  padding: 7px 18px;
  text-align: center;
  border-radius: 3px;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: none;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

const Content = styled.div`
  ${Body1}
  text-align: left;
  display: flex;
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${COLOR_WHITE};
  padding: 20px;
  border-radius: 10px;
`;

// const ExitButton = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   cursor: pointer;
//   border-radius: 50%;
//   width: 30px;
//   height: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${COLOR_GRAY};

//   &:after {
//     content: "✕";
//     font-size: x-large;
//     font-weight: bolder;
//   }

//   &:hover {
//     color: ${COLOR_BLACK};
//   }
// `;

type AlertModalProps = {
  message: string;
  isOpen: boolean;
  handleClose?: () => void;
};

export default function AlertModal({
  message,
  isOpen,
  handleClose,
}: AlertModalProps) {
  return (
    <Modal show={isOpen} onHide={handleClose}>
      <Modal.Body>
        <ModalContent>
          {/* <ExitButton onClick={handleClose} /> */}
          <Content>{message}</Content>
          <OkButton onClick={handleClose}>Ok</OkButton>
        </ModalContent>
      </Modal.Body>
    </Modal>
  );
}
